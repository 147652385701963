import { useAuth0 } from '@auth0/auth0-react'
import { Chip } from '@mui/material'
import { CheckCircle as InStockIcon, AccessTime as OutOfStockIcon } from '@mui/icons-material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ITool } from '../../../../../interfaces/tool.interface'

interface IProps {
    readonly tool: ITool
}

const StockLabel: FC<IProps> = ({ tool }) => {
    const { t } = useTranslation()
    const { isAuthenticated } = useAuth0()

    if (!isAuthenticated) {
        return <></>
    } else if (tool.stock > 0) {
        return (
            <>
                <Chip
                    icon={<InStockIcon />}
                    label={`${tool.stock} ${t('common:in-stock')}`}
                    color='success'
                    variant='filled'
                />
            </>
        )
    } else {
        return (
            <>
                <Chip
                    icon={<OutOfStockIcon />}
                    label={t('common:out-of-stock')}
                    color='info'
                    variant='filled'
                />
            </>
        )
    }
}

export default StockLabel
