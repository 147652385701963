import { Divider, Tab, Tabs } from '@mui/material'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IToolMetaFragment } from '../../../fragments/tool-meta.fragment'
import { ProductTabEnum, SelectTabType } from '../../../interfaces/main-page.interface'
import { ITool } from '../../../interfaces/tool.interface'
import Skeleton from '../Skeleton'
import TabPanel from '../TabPanel'
import GeneralTab from './GeneralTab'
import Sorting from './Sorting'
import TechnicalDetailsTab from './TechnicalDetailsTab'

interface IProps {
    readonly selectedTab: ProductTabEnum
    readonly tools: ITool[]
    readonly areToolsLoading: boolean
    readonly toolMeta: IToolMetaFragment
    readonly selectTab: SelectTabType
}

const StyledResults = styled.div``

const StyledResultsHead = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledTabsContainer = styled.div`
    position: relative;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const Results: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
    { selectedTab, tools, areToolsLoading, toolMeta, selectTab },
    ref
) => {
    const { t } = useTranslation()

    return (
        <StyledResults ref={ref}>
            <StyledResultsHead>
                <StyledTabsContainer>
                    <Tabs
                        value={selectedTab}
                        indicatorColor='primary'
                        textColor='primary'
                        onChange={selectTab}
                        aria-label='disabled tabs example'
                    >
                        <Tab value={ProductTabEnum.GENERAL} label={t('general')} />
                        <Tab
                            value={ProductTabEnum.TECHNICAL_DETAILS}
                            label={t('technical-details')}
                        />
                    </Tabs>
                </StyledTabsContainer>
                <Sorting toolMeta={toolMeta} />
            </StyledResultsHead>
            <Divider light />

            {areToolsLoading && <Skeleton />}
            {!areToolsLoading && (
                <>
                    <TabPanel value={selectedTab} index={ProductTabEnum.GENERAL}>
                        <GeneralTab tools={tools} />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={ProductTabEnum.TECHNICAL_DETAILS}>
                        <TechnicalDetailsTab meta={toolMeta} tools={tools} />
                    </TabPanel>
                </>
            )}
        </StyledResults>
    )
}

export default forwardRef<HTMLDivElement, IProps>(Results)
