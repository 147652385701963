import { Divider, useMediaQuery, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { ITool } from '../../../../interfaces/tool.interface'
import DesktopToolItem from './DesktopToolItem'
import MobileToolItem from './MobileToolItem'

interface IProps {
    readonly tools: ITool[]
}

const GeneralTab: FC<IProps> = ({ tools }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            {tools.map((tool) => (
                <React.Fragment key={tool.id}>
                    {!isMobile && <DesktopToolItem tool={tool} />}
                    {isMobile && <MobileToolItem tool={tool} />}
                    <Divider light />
                </React.Fragment>
            ))}
        </>
    )
}

export default GeneralTab
