import { Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFiltersGroupMeta } from '../../../../interfaces/filters-meta.interface'
import { FilterValueType, IFilters } from '../../../../interfaces/filters.interface'
import SelectFilter from '../SelectFilter'

interface IProps {
    readonly className?: string
    readonly toolsGroup: IFiltersGroupMeta
    readonly filters: IFilters
    readonly filtersValues: FilterValueType[]
}

const verticalFilters = ['coupling-interface', 'drive']

const StyledFiltersContainer = styled.div`
    width: 100%;
`

const StyledGroup = styled.div`
    display: grid;
    grid-column-gap: 100px;
    grid-template-areas:
        'general .'
        'turret-interface coupling-interface'
        'tool-holder drive'
        'other .';
    grid-template-columns: 1fr 1fr;

    ${({ theme }) => theme.breakpoints.down('xl')} {
        grid-column-gap: 70px;
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        grid-template-columns: 1fr;

        grid-template-areas:
            'general'
            'turret-interface'
            'drive'
            'coupling-interface'
            'tool-holder'
            'other';
    }
`

interface IStyledSubgroup {
    readonly name: string
}

const StyledSubgroup = styled.div<IStyledSubgroup>`
    grid-area: ${({ name }) => name};
    &:not(:last-child) {
        margin-bottom: 54px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
`

const StyledSubgroupTitleTypography = styled(Typography)`
    font-size: 18px;
    margin-bottom: 8px;
`

interface IStyledSubgroupFilters {
    readonly $isOneColumnForLg: boolean
}

const StyledSubgroupFilters = styled.div<IStyledSubgroupFilters>`
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr 1fr;

    ${({ theme }) => theme.breakpoints.down('xl')} {
        grid-column-gap: 32px;
        grid-template-columns: ${({ $isOneColumnForLg }) =>
            $isOneColumnForLg ? '1fr' : '1fr 1fr'};
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        grid-template-columns: 1fr 1fr;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`

const StyledTypographyContainer = styled.div`
    margin-bottom: 12px;
`

const StyledTypography = styled(Typography)`
    font-size: 24px;
`

const ToolsFiltersGroup: FC<IProps> = ({ className, toolsGroup, filters, filtersValues }) => {
    const { t } = useTranslation()
    const [highlightedDepsIds, setHighlightedDepsIds] = useState<string[]>([])

    return (
        <StyledFiltersContainer className={className}>
            <StyledTypographyContainer>
                <StyledTypography style={{ margin: 0 }}>
                    {t(`filters:${toolsGroup.label}`)}
                </StyledTypography>
            </StyledTypographyContainer>
            <StyledGroup>
                {toolsGroup.subgroups.map(({ name, label, filters: filtersMeta }) => {
                    return (
                        <StyledSubgroup key={name} name={name}>
                            {label && (
                                <StyledSubgroupTitleTypography>
                                    {t(`filters:${label}`)}
                                </StyledSubgroupTitleTypography>
                            )}
                            <StyledSubgroupFilters
                                $isOneColumnForLg={verticalFilters.includes(name)}
                            >
                                {filtersMeta.map((filterMeta) => {
                                    return (
                                        <SelectFilter
                                            key={filterMeta.id}
                                            filterMeta={filterMeta}
                                            filters={filters}
                                            filtersValues={filtersValues}
                                            isHighlighted={highlightedDepsIds.includes(
                                                filterMeta.id
                                            )}
                                            setHighlightedDepsIds={setHighlightedDepsIds}
                                        />
                                    )
                                })}
                            </StyledSubgroupFilters>
                        </StyledSubgroup>
                    )
                })}
            </StyledGroup>
        </StyledFiltersContainer>
    )
}

export default ToolsFiltersGroup
