import { Menu, MenuItem, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { IToolMetaFragment } from '../../../../fragments/tool-meta.fragment'
import { SortingMethodEnum, SortingOrderEnum } from '../../../../interfaces/sorting.interface'
import { changeSortingMethod } from '../../../../state/actions/filters/change-sorting-method'
import { IReduxState } from '../../../../state/interfaces/state.interface'

interface IProps {
    readonly toolMeta: IToolMetaFragment
}

const StyledSorting = styled.div`
    display: flex;
    align-items: center;
`

const StyledSortingHead = styled.div`
    display: flex;
`

const StyledSortingLabel = styled.div``
const StyledSortingValue = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 20px;
    justify-content: flex-end;
`

const StyledSortingTypography = styled(Typography)`
    color: var(--text-disabled-color);
`

const StyledSortingValueTypography = styled(Typography)`
    margin-left: 8px;
`

interface IStyledSortingOptionTypography {
    readonly isActive: boolean
}

const StyledSortingOptionTypography = styled(Typography)<IStyledSortingOptionTypography>`
    ${({ isActive }) => isActive && 'font-weight: 500;'}
`

const StyledMenuItem = styled(MenuItem)`
    &&:hover {
        background: var(--info-light-color);
    }

    &:nth-of-type(even) {
        background: var(--grey-100-color);
    }
`

const StyledOptionItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

interface IStyledArrowUpwardIcon {
    readonly isDesc: boolean
}

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)<IStyledArrowUpwardIcon>`
    transform: rotate(${({ isDesc }) => (isDesc ? 180 : 360)}deg);
    transition: transform 200ms ease-in-out;
    margin-left: 8px;
    margin-right: 12px;
`

const Sorting: FC<IProps> = ({ toolMeta }) => {
    const { t } = useTranslation()

    const reduxDispatch = useDispatch()
    const [isSortingOpened, setIsSortingOpened] = useState(false)
    const sortingButton = useRef(null)
    const sorting = useSelector((state: IReduxState) => state.filters.sorting)

    const sortingOptions = toolMeta.detail.filter(({ sortable }) => sortable)

    const currentSortingId = sorting.property?.split(':')[1]

    const sortingLabel =
        sorting.method === SortingMethodEnum.RELEVANCE
            ? 'relevance'
            : sortingOptions.find(({ id }) => id === currentSortingId)?.label || 'relevance'

    return (
        <StyledSorting>
            <StyledSortingHead>
                <StyledSortingLabel>
                    <StyledSortingTypography variant='body1'>
                        {t('sort-by')}:
                    </StyledSortingTypography>
                </StyledSortingLabel>
                <StyledSortingValue
                    aria-controls='sorting-menu'
                    ref={sortingButton}
                    onClick={() => setIsSortingOpened(true)}
                >
                    <StyledSortingValueTypography variant='body1'>
                        {t(sortingLabel)}
                    </StyledSortingValueTypography>
                    {sorting.method !== SortingMethodEnum.RELEVANCE && (
                        <StyledArrowUpwardIcon isDesc={sorting.order === SortingOrderEnum.DESC} />
                    )}
                    {isSortingOpened && <ArrowDropUpIcon />}
                    {!isSortingOpened && <ArrowDropDownIcon />}
                </StyledSortingValue>
            </StyledSortingHead>
            <Menu
                variant='menu'
                open={isSortingOpened}
                anchorEl={sortingButton.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClick={() => setIsSortingOpened(false)}
            >
                <StyledMenuItem
                    key='relevance'
                    value={SortingMethodEnum.RELEVANCE}
                    onClick={() => {
                        reduxDispatch(
                            changeSortingMethod({
                                newMethod: SortingMethodEnum.RELEVANCE,
                                newProperty: null,
                                newOrder: SortingOrderEnum.DESC,
                            })
                        )
                    }}
                >
                    <StyledSortingOptionTypography
                        variant='body1'
                        isActive={sorting.method === SortingMethodEnum.RELEVANCE}
                    >
                        {t('relevance')}
                    </StyledSortingOptionTypography>
                </StyledMenuItem>
                {sortingOptions.map(({ id, label }) => {
                    const fullId = `tool:${id}`
                    const isActive = sorting.property === fullId
                    return (
                        <StyledMenuItem
                            key={`${id}-asc`}
                            value={id}
                            onClick={() => {
                                reduxDispatch(
                                    changeSortingMethod({
                                        newMethod: SortingMethodEnum.PROPERTY,
                                        newProperty: fullId,
                                        newOrder:
                                            isActive && sorting.order === SortingOrderEnum.ASC
                                                ? SortingOrderEnum.DESC
                                                : SortingOrderEnum.ASC,
                                    })
                                )
                            }}
                        >
                            <StyledOptionItem>
                                <StyledSortingOptionTypography variant='body1' isActive={isActive}>
                                    {`${t(label)}`}
                                </StyledSortingOptionTypography>
                                {isActive && (
                                    <StyledArrowUpwardIcon
                                        isDesc={sorting.order === SortingOrderEnum.DESC}
                                    />
                                )}
                            </StyledOptionItem>
                        </StyledMenuItem>
                    )
                })}
            </Menu>
        </StyledSorting>
    )
}

export default Sorting
