import { Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
    readonly label: string | null
    readonly value: string
    readonly onClick: () => void
}

const StyledContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.74);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 9px 6px 14px;
    margin: 10px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        svg {
            transform: scale(1.2);
        }
    }
`

const StyledText = styled.div`
    display: flex;
`
const StyledLabel = styled.div`
    color: rgba(0, 0, 0, 0.38);
`
const StyledValue = styled.div`
    color: rgba(0, 0, 0, 0.87);
`
const StyledDeleteIconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 14px;
    width: 20px;
    height: 20px;

    svg {
        width: 100%;
        height: 100%;
    }
`

const CustomChip: FC<IProps> = ({ label, value, onClick }) => {
    const { t } = useTranslation()

    return (
        <StyledContainer onClick={onClick}>
            <StyledText>
                {label && (
                    <StyledLabel>
                        <Typography variant='body2'>{t(`filters:${label}`)}:&nbsp;</Typography>
                    </StyledLabel>
                )}
                <StyledValue>
                    <Typography variant='body2'>{value}</Typography>
                </StyledValue>
            </StyledText>
            <StyledDeleteIconContainer>
                <Close />
            </StyledDeleteIconContainer>
        </StyledContainer>
    )
}

export default CustomChip
