import styled from 'styled-components'

interface IStyledContainer {
    readonly isSticked: boolean
}

export const StyledContainer = styled.div<IStyledContainer>`
    margin: 0 calc(-1 * var(--layout-side-padding));
    padding: 18px var(--layout-side-padding);
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 100;
    border-bottom: 1px solid;
    transition: border-color 0.25s linear, opacity 0.25s linear;
    border-color: ${({ isSticked }) => (isSticked ? 'var(--text-primary-color)' : 'rgba(0,0,0,0)')};
`

export const StyledInner = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    min-height: 34px;
`

export const StyledListContainer = styled.div`
    margin: -10px 180px -10px -10px;
    display: flex;
    flex-wrap: wrap;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: -10px;
    }
`

export const StyledFiltersClear = styled.div`
    display: flex;
    align-items: center;
    margin: 10px;
`

const StyledCommonMoreLessChip = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    display: flex;
    align-items: center;
    height: 32px;
    margin: 10px;
    padding: 0 16px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`

export const ShowMoreChip = styled(StyledCommonMoreLessChip)``
export const ShowLessChip = styled(StyledCommonMoreLessChip)``

export const StyledRight = styled.div`
    position: absolute;
    right: 0;
    top: -7px;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        top: 5px;
    }
`

export const StyledIconUpContainer = styled.div`
    margin-right: 30px;
    padding: 3px 12px;
    height: 32px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: var(--info-light-color);
    border-radius: 20px;
    border: 1px solid var(--grey-300-color);
    svg {
        height: 24px;
    }

    &:hover {
        background-color: var(--info-main-color);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

export const StyledMobileUp = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 56px;
    height: 56px;
    background: #ff505f;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;

    svg {
        fill: white;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: flex;
    }
`
