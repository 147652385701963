import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export interface IChangeFiltersValuesActionParams {
    readonly objectType: string
    readonly attribute: string
    readonly newValues: string[] | string | null
}

export const changeFiltersValues =
    (params: IChangeFiltersValuesActionParams) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'CHANGE_FILTERS_VALUES',
            payload: params,
        })
    }
