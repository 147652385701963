import { FilterMetaTypeEnum, IFilterMeta } from '../../../interfaces/filters-meta.interface'
import {
    FilterValueType,
    IFilterValueListItem,
    IFilterValueRange,
} from '../../../interfaces/filters.interface'

interface IUnifiedFiltersValue {
    readonly value: string
    readonly objectType: string
    readonly attribute: string
    readonly type: FilterMetaTypeEnum
}

export const prepareChipsValues = (filtersValues: FilterValueType[]): IUnifiedFiltersValue[] =>
    filtersValues.flatMap((filterValue: FilterValueType) => {
        if (filterValue.type === FilterMetaTypeEnum.SINGLE) {
            return filterValue
        }

        if (filterValue.type === FilterMetaTypeEnum.LIST) {
            const { attribute, objectType, values, type } = filterValue as IFilterValueListItem

            return values.map((value) => ({
                value,
                attribute,
                objectType,
                type,
            }))
        }

        // filterValue.type === FilterValueTypeEnum.RANGE
        const castedFilterGroup = filterValue as IFilterValueRange
        const { objectType, attribute, from, to, type } = castedFilterGroup

        return {
            value: `${from} - ${to}`,
            attribute,
            objectType,
            type: type as FilterMetaTypeEnum,
        }
    })

export const findFilterLabelById = (
    flattenFiltersMeta: IFilterMeta[],
    id: string
): string | null => {
    return flattenFiltersMeta.find(({ id: currentId }) => currentId === id)?.label || null
}
