import { useDispatch } from 'react-redux'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import intersection from 'lodash/intersection'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'

import { TextField, Tooltip, Typography } from '@mui/material'
import { Autocomplete } from '@mui/material'

import { IFilterMeta } from '../../../../../interfaces/filters-meta.interface'
import { FilterValueType, IFilterValueListItem } from '../../../../../interfaces/filters.interface'
import { changeFiltersValues } from '../../../../../state/actions/filters/change-filters-values'

interface IProps {
    readonly className?: string
    readonly filterMeta: IFilterMeta
    readonly existedOptions: string[]
    readonly filtersValues: FilterValueType[]
    readonly isHighlighted: boolean
    readonly isDisabled: boolean
    readonly disabledMessage: string | null
}

const StyledTypographyValue = styled(Typography)`
    font-size: 15px;
    line-height: 31px;
    max-width: 106px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface IStyledTextField {
    readonly highlighted: number
}

/* emotion adds redundant DOM node during page init render and it breaks MUI selector "label + .MuiInput-root"  */
const StyledTextField = styled(TextField)<IStyledTextField>`
    & > label ~ .MuiInput-root {
        margin-top: 16px;
    }

    ${({ highlighted }) =>
        highlighted &&
        css`
            & .MuiInput-underline:before {
                border-bottom: 2px solid red;
            }
        `}
`

interface IAutocompleteOption {
    readonly label: string
    readonly value: string
}

const MultipleSelect: FC<IProps> = ({
    className,
    filterMeta,
    existedOptions,
    filtersValues,
    isHighlighted,
    isDisabled,
    disabledMessage,
}) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()

    const { id, label } = filterMeta

    const [objectType, attribute] = id.split(':')
    const values =
        (
            filtersValues.find(
                (filterValue) =>
                    filterValue.objectType === objectType && filterValue.attribute === attribute
            ) as IFilterValueListItem
        )?.values || []

    const inputValue =
        values.length > 0
            ? intersection(values, existedOptions)
            : existedOptions.length === 1
            ? [existedOptions[0]]
            : []
    const singleOptionHint = existedOptions.length === 1 ? t('filters:auto-filled') : null

    // TODO: remove this function asap
    function translateLabel(label: string): string {
        if (
            objectType === 'tool' &&
            attribute === 'design' &&
            label === 'abgewinkelt zurueckgesetzt'
        ) {
            return t(`tools:design.90_zurueckgesetzt`)
        } else {
            const buckets = [
                'weight',
                'shaft_length',
                'nominal_height',
                'torque',
                'speed',
                'max_power',
            ]

            const dictionaryName =
                ['manufacturer_name', 'shaft_type', 'shaft_size'].includes(attribute) ||
                (objectType === 'machine' && attribute === 'model')
                    ? 'machines'
                    : 'tools'
            const value =
                snakeCase(
                    buckets.includes(attribute)
                        ? label.replace('unter ', '').replace('über ', '').replace(' bis ', ' – ')
                        : label
                ) || 'null'
            return t(`${dictionaryName}:${attribute}.${value}`)
        }
    }

    return (
        <Autocomplete
            className={className}
            id={`autocomplete-${id}`}
            multiple
            options={existedOptions.map((option) => ({
                label: option,
                value: option,
            }))}
            renderTags={(options) => {
                if (options.length > 1) {
                    return (
                        <StyledTypographyValue>
                            {`${options.length} ${t('filters:selected')}`}
                        </StyledTypographyValue>
                    )
                }
                return (
                    <StyledTypographyValue>
                        {translateLabel((options[0] as IAutocompleteOption).label)}
                    </StyledTypographyValue>
                )
            }}
            getOptionLabel={(option) => (option as IAutocompleteOption).label as string}
            renderInput={(params) => {
                const input = (
                    <StyledTextField
                        {...params}
                        variant='standard'
                        highlighted={isHighlighted ? 1 : 0}
                        label={t(`filters:${label}`)}
                    />
                )

                if (singleOptionHint !== null || (isDisabled && disabledMessage)) {
                    return (
                        <Tooltip
                            title={
                                <Typography variant='caption'>
                                    {singleOptionHint || disabledMessage}
                                </Typography>
                            }
                            placement='bottom'
                        >
                            {input}
                        </Tooltip>
                    )
                }

                return input
            }}
            disabled={singleOptionHint !== null || isDisabled}
            onChange={(event, value) => {
                reduxDispatch(
                    changeFiltersValues({
                        objectType,
                        attribute,
                        newValues: (value as IAutocompleteOption[]).map((option) => option.value),
                    })
                )
            }}
            isOptionEqualToValue={(option, value) => {
                return (
                    (option as IAutocompleteOption).value === (value as IAutocompleteOption).value
                )
            }}
            value={inputValue.map((option) => ({
                label: option,
                value: option,
            }))}
        />
    )
}

export default MultipleSelect
