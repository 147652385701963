import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export interface IAddOptionToFilterActionParams {
    readonly objectType: string
    readonly attribute: string
    readonly option: string
}

export const addOptionToFilter =
    (params: IAddOptionToFilterActionParams) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'ADD_OPTION_TO_FILTER',
            payload: params,
        })
    }
