import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const changePage =
    (page: number) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'CHANGE_PAGE',
            payload: page,
        })
    }
