import { TFunction } from 'i18next'
import _ from 'lodash'
import {
    FilterMetaTypeEnum,
    IDependencyItem,
    IFiltersGroupMeta,
} from '../interfaces/filters-meta.interface'
import { FilterValueType, IFilters, IFilterValueListItem } from '../interfaces/filters.interface'

// disabled if any of the deps is not chosen or chosen with another value
// enabled if dependency has only one available value
export const isDisabled = (
    filters: IFilters,
    filtersValues: FilterValueType[],
    dependencies?: IDependencyItem[]
): boolean =>
    (dependencies || []).some(({ id, values: depValues }) => {
        const dependencyHasSingleValue = _.keys(filters[id]).length === 1

        if (dependencyHasSingleValue) {
            return false
        }

        return filtersValues.every((filterValue) => {
            const { objectType, attribute } = filterValue

            if (`${objectType}:${attribute}` !== id) {
                return true
            }

            if (!depValues) {
                return false
            }

            if (!('values' in filterValue)) {
                return true
            }
            const { values } = filterValue as IFilterValueListItem
            return _.intersection(depValues, values).length === 0
        })
    })

interface IGenerateDisabledMessageParams {
    readonly label: string
    readonly dependencies: IDependencyItem[]
    readonly filtersMeta: IFiltersGroupMeta[]
    readonly translate: TFunction
}

export const generateDisabledMessage = ({
    label,
    dependencies,
    filtersMeta,
    translate,
}: IGenerateDisabledMessageParams): string => {
    const singleDependency = dependencies[0]
    const { id, values } = singleDependency

    if (!values || values.length === 0) {
        const dependencyLabel =
            filtersMeta
                .flatMap(({ subgroups }) => subgroups)
                .flatMap(({ filters }) => filters)
                .find(({ id: currentId }) => id === currentId)?.label || ''
        return translate('filters:fill-filter', {
            dependencyLabel: translate(`filters:${dependencyLabel}`),
        })
    }

    return translate('filters:available-for-values', {
        label: translate(`filters:${label}`),
        values: values?.join(', '),
    })
}

export const getFilterTypeById = (
    id: string,
    filtersGroupsMeta: IFiltersGroupMeta[]
): FilterMetaTypeEnum | null =>
    filtersGroupsMeta
        .flatMap(({ subgroups }) => subgroups)
        .flatMap(({ filters }) => filters)
        .find(({ id: currentId }) => id === currentId)?.type || null
