import React, { ChangeEvent, FunctionComponent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon, InputBase } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

interface IProps {
    readonly searchValue: string
    readonly areToolsLoading: boolean
    readonly onSubmit: () => void
    readonly onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const StyledForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-400-color);
    border-radius: 4px;
    width: 996px;
    box-sizing: border-box;
    max-width: 100%;

    ${({ theme }) => theme.breakpoints.down('xl')} {
        width: 800px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: auto;
    }
`

const StyledSpinIcon = styled(Icon)`
    color: #ffffff;
`

const StyledSearchIconContainer = styled.div`
    background: var(--primary-main-color);
    width: 76px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
    margin: -1px -1px -1px 0;
    cursor: pointer;
`

const StyledSearchOutlined = styled(SearchOutlined)`
    color: #ffffff;
`

const StyledInput = styled(InputBase)`
    color: rgba(0, 0, 0, 0.87);
    padding-left: 22px;
    flex-grow: 1;
`

const SearchInput: FunctionComponent<IProps> = ({
    searchValue,
    areToolsLoading,
    onSubmit,
    onChange,
}) => {
    const { t } = useTranslation()

    return (
        <StyledForm
            onSubmit={(event) => {
                event.preventDefault()
                onSubmit()
            }}
        >
            <StyledInput
                value={searchValue}
                placeholder={t('search-tools')}
                inputProps={{ 'aria-label': 'search tools' }}
                onChange={onChange}
            />
            <StyledSearchIconContainer
                onClick={() => {
                    if (!areToolsLoading) {
                        onSubmit()
                    }
                }}
            >
                {!areToolsLoading && <StyledSearchOutlined />}
                {areToolsLoading && <StyledSpinIcon className='fa fa-spinner fa-spin' />}
            </StyledSearchIconContainer>
        </StyledForm>
    )
}

export default SearchInput
