import queryString from 'query-string'
import { FilterMetaTypeEnum, IFiltersGroupMeta } from '../interfaces/filters-meta.interface'
import {
    FilterValueType,
    IFilterValueListItem,
    IFilterValueRange,
    IFilterValueSingleItem,
} from '../interfaces/filters.interface'
import { SearchParamsType } from '../interfaces/search-params.interface'
import { SortingMethodEnum, SortingOrderEnum } from '../interfaces/sorting.interface'
import { getFilterTypeById } from './filters'

const TYPE_ATTRIBUTE_URL_DELIMITER = '__'
const LIST_ITEM_VALUES_URL_DELIMITER = ','
const RANGE_FROM_TO_URL_DELIMITER = ';'

export const stringifyFiltersValues = (filtersValues: FilterValueType[]): string => {
    const singleFiltersValues = filtersValues.filter(
        ({ type }) => type === FilterMetaTypeEnum.SINGLE
    ) as IFilterValueSingleItem[]
    const listFiltersValues = filtersValues.filter(
        ({ type }) => type === FilterMetaTypeEnum.LIST
    ) as IFilterValueListItem[]
    const rangeFiltersValues = filtersValues.filter(
        ({ type }) => type === FilterMetaTypeEnum.RANGE
    ) as IFilterValueRange[]

    return [
        ...singleFiltersValues.map(
            ({ objectType, attribute, value }) => `${objectType}${attribute}${value}`
        ),
        ...listFiltersValues.map(
            ({ objectType, attribute, values }) => `${objectType}${attribute}${values}`
        ),
        ...rangeFiltersValues.map(
            ({ objectType, attribute, from, to }) => `${objectType}${attribute}${from}${to}`
        ),
    ].join('')
}

export const extractSearchParamsFromQS = (
    locationSearch: string,
    defaultSearchParams: SearchParamsType,
    filtersGroupsMeta: IFiltersGroupMeta[]
): SearchParamsType => {
    if (!locationSearch) {
        return defaultSearchParams
    }

    const { q, page, sort, order, ...filtersObj } = queryString.parse(locationSearch)
    const filtersValues = Object.entries(filtersObj)
        .map(([filterName, filterValue]) => {
            if (!filterValue) {
                return null
            }

            const [objectType, attribute] = filterName.split(TYPE_ATTRIBUTE_URL_DELIMITER)

            const filterType = getFilterTypeById(`${objectType}:${attribute}`, filtersGroupsMeta)

            if (!filterType) {
                return null
            }

            const castedValue = filterValue as string

            if (filterType === FilterMetaTypeEnum.RANGE) {
                const [from, to] = castedValue.split(RANGE_FROM_TO_URL_DELIMITER)
                return {
                    objectType,
                    attribute,
                    type: filterType,
                    from: Number(from),
                    to: Number(to),
                }
            }

            if (filterType === FilterMetaTypeEnum.LIST) {
                return {
                    objectType,
                    attribute,
                    type: filterType,
                    values: castedValue.split(LIST_ITEM_VALUES_URL_DELIMITER),
                }
            }

            // filterType === FilterMetaTypeEnum.SINGLE
            return {
                objectType,
                attribute,
                type: filterType,
                value: castedValue,
            }
        })
        .filter((x) => x) as FilterValueType[]

    return {
        searchValue: (q as string) || defaultSearchParams.searchValue,
        page: Number(page) || defaultSearchParams.page,
        filtersValues: filtersValues || defaultSearchParams.filtersValues,
        sortingMethod: sort ? SortingMethodEnum.PROPERTY : SortingMethodEnum.RELEVANCE,
        sortingProperty: sort ? (Array.isArray(sort) ? sort[0] : sort) : null,
        sortingOrder: order
            ? ((Array.isArray(order) ? order[0] : order) as SortingOrderEnum)
            : SortingOrderEnum.DESC,
    }
}

export const convertSearchParamsToQS = (searchParams: SearchParamsType): string => {
    const { searchValue, page, filtersValues, sortingMethod, sortingOrder, sortingProperty } =
        searchParams
    const searchParamsPrepared = {
        q: searchValue || undefined,
        page: page === 1 ? undefined : page,
        sort:
            sortingMethod === SortingMethodEnum.RELEVANCE
                ? undefined
                : sortingProperty || undefined,
        order:
            sortingMethod === SortingMethodEnum.RELEVANCE && sortingOrder === SortingOrderEnum.DESC
                ? undefined
                : sortingOrder,
        ...filtersValues.reduce((acc, filterValue) => {
            if (filterValue.type === FilterMetaTypeEnum.SINGLE) {
                const { attribute, objectType, value } = filterValue as IFilterValueSingleItem
                acc[`${objectType}${TYPE_ATTRIBUTE_URL_DELIMITER}${attribute}`] = value
                return acc
            }

            if (filterValue.type === FilterMetaTypeEnum.LIST) {
                const { attribute, objectType, values } = filterValue as IFilterValueListItem
                acc[`${objectType}${TYPE_ATTRIBUTE_URL_DELIMITER}${attribute}`] = values.join(
                    LIST_ITEM_VALUES_URL_DELIMITER
                )
                return acc
            }

            const { attribute, objectType, from, to } = filterValue as IFilterValueRange
            acc[
                `${objectType}${TYPE_ATTRIBUTE_URL_DELIMITER}${attribute}`
            ] = `${from}${RANGE_FROM_TO_URL_DELIMITER}${to}`
            return acc
        }, {} as Record<string, string>),
    }

    return queryString.stringify(searchParamsPrepared)
}
