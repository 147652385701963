import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { extractSearchParamsFromQS } from '../../helpers/url-search-params'
import { FilterMetaTypeEnum, IFiltersGroupMeta } from '../../interfaces/filters-meta.interface'
import { IFilterValueListItem, IFilterValueSingleItem } from '../../interfaces/filters.interface'
import { SearchParamsType } from '../../interfaces/search-params.interface'
import { SortingMethodEnum, SortingOrderEnum } from '../../interfaces/sorting.interface'
import { changeFiltersValues } from '../../state/actions/filters/change-filters-values'
import { changePage } from '../../state/actions/filters/change-page'
import { changeSearchValue } from '../../state/actions/filters/change-search-value'
import { changeSortingMethod } from '../../state/actions/filters/change-sorting-method'
import { fetchFiltersMeta } from '../../state/actions/filters/fetch-filters-meta'
import { AppDispatchType } from '../../state/interfaces/app-dispatch.type'

export const useQSToSearchParams = (
    locationSearch: string,
    filtersGroupsMeta: IFiltersGroupMeta[]
): void => {
    const reduxDispatch: AppDispatchType = useDispatch()

    useEffect(() => {
        const defaultSearchParams: SearchParamsType = {
            searchValue: '',
            page: 1,
            filtersValues: [],
            sortingMethod: SortingMethodEnum.RELEVANCE,
            sortingOrder: SortingOrderEnum.DESC,
            sortingProperty: null,
        }

        const searchParams = extractSearchParamsFromQS(
            locationSearch,
            defaultSearchParams,
            filtersGroupsMeta
        )
        reduxDispatch(fetchFiltersMeta(filtersGroupsMeta))
        reduxDispatch(changePage(searchParams.page))
        reduxDispatch(changeSearchValue(searchParams.searchValue))
        reduxDispatch(
            changeSortingMethod({
                newMethod: searchParams.sortingMethod,
                newProperty: searchParams.sortingProperty,
                newOrder: searchParams.sortingOrder,
            })
        )
        searchParams.filtersValues.map((filterValue) => {
            const newValues =
                filterValue.type === FilterMetaTypeEnum.SINGLE
                    ? (filterValue as IFilterValueSingleItem).value
                    : (filterValue as IFilterValueListItem).values
            reduxDispatch(
                changeFiltersValues({
                    objectType: filterValue.objectType,
                    attribute: filterValue.attribute,
                    newValues,
                })
            )
        })
    }, [])
}
