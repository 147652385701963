import { useDispatch } from 'react-redux'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TextField, Tooltip, Typography } from '@mui/material'
import { Autocomplete } from '@mui/material'

import { IFilterMeta } from '../../../../../interfaces/filters-meta.interface'
import {
    FilterValueType,
    IFilterValueSingleItem,
} from '../../../../../interfaces/filters.interface'
import { changeFiltersValues } from '../../../../../state/actions/filters/change-filters-values'

interface IProps {
    readonly className?: string
    readonly filterMeta: IFilterMeta
    readonly existedOptions: string[]
    readonly filtersValues: FilterValueType[]
    readonly isHighlighted: boolean
    readonly isDisabled: boolean
    readonly disabledMessage: string | null
}

interface IStyledTextField {
    readonly highlighted: number
}

/* emotion adds redundant DOM node during page init render and it breaks MUI selector "label + .MuiInput-root"  */
const StyledTextField = styled(TextField)<IStyledTextField>`
    & > label ~ .MuiInput-root {
        margin-top: 16px;
    }

    ${({ highlighted }) =>
        highlighted &&
        css`
            & .MuiInput-underline:before {
                border-bottom: 2px solid red;
            }
        `}
`

const SingleSelect: FC<IProps> = ({
    className,
    filterMeta,
    existedOptions,
    filtersValues,
    isHighlighted,
    isDisabled,
    disabledMessage,
}) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()

    const { id, label } = filterMeta

    const [objectType, attribute] = id.split(':')
    const filterValue = filtersValues.find(
        (filterValue) =>
            filterValue.objectType === objectType && filterValue.attribute === attribute
    ) as IFilterValueSingleItem

    const singleOptionHint = existedOptions.length === 1 ? t('filters:auto-filled') : null

    return (
        <Autocomplete
            className={className}
            id={`autocomplete-${id}`}
            options={existedOptions}
            getOptionLabel={(option) => option as string}
            renderInput={(params) => {
                const input = (
                    <StyledTextField
                        {...params}
                        variant='standard'
                        highlighted={isHighlighted ? 1 : 0}
                        label={t(`filters:${label}`)}
                    />
                )

                if (singleOptionHint !== null || (isDisabled && disabledMessage)) {
                    return (
                        <Tooltip
                            title={
                                <Typography variant='caption'>
                                    {singleOptionHint || disabledMessage}
                                </Typography>
                            }
                            placement='bottom'
                        >
                            {input}
                        </Tooltip>
                    )
                }

                return input
            }}
            disabled={singleOptionHint !== null || isDisabled}
            onChange={(event, value) => {
                reduxDispatch(
                    changeFiltersValues({
                        objectType,
                        attribute,
                        newValues: value as string | null,
                    })
                )
            }}
            isOptionEqualToValue={(option, value) => {
                return option === value
            }}
            value={filterValue?.value || (existedOptions.length === 1 ? existedOptions[0] : null)}
        />
    )
}

export default SingleSelect
