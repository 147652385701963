import { Button, Typography } from '@mui/material'
import { Pagination } from '@mui/material'
import _ from 'lodash'
import React, { ChangeEvent, FC, RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { PER_PAGE } from '../../constants/main'
import { IFilterMetaFragment } from '../../fragments/filter-meta.fragment'
import { IToolMetaFragment } from '../../fragments/tool-meta.fragment'
import { IFetchToolsParams } from '../../helpers/fetch-tools'
import { IFiltersGroupMeta } from '../../interfaces/filters-meta.interface'
import { FilterValueType, IFilters } from '../../interfaces/filters.interface'
import { IMachine } from '../../interfaces/machine.interface'
import { ProductTabEnum, SelectTabType } from '../../interfaces/main-page.interface'
import { ITool } from '../../interfaces/tool.interface'
import { changeSearchValue } from '../../state/actions/filters/change-search-value'
import ChosenFilters from './ChosenFilters'
import Results from './Results'
import SearchInput from './SearchInput'
import TopFilters from './TopFilters'

interface IProps {
    readonly searchContainerRef: RefObject<HTMLDivElement>
    readonly toolMeta: IToolMetaFragment
    readonly filterMeta: IFilterMetaFragment
    readonly tools: ITool[]
    readonly filters: IFilters
    readonly filtersValues: FilterValueType[]
    readonly searchValue: string
    readonly totalResults: number
    readonly areToolsLoading: boolean
    readonly page: number
    readonly selectedMachine: IMachine | null
    readonly selectedTab: ProductTabEnum
    readonly search: _.DebouncedFunc<(params: IFetchToolsParams) => Promise<void>>
    readonly onCustomPage: (page: number) => void
    readonly selectTab: SelectTabType
}

const StyledContainer = styled.div`
    display: flex;
`

const StyledPagination = styled(Pagination)`
    margin: 50px 0;
`

const StyledCenterColumn = styled.div`
    background: ${({ theme }) => theme.palette.common.white};
    padding: 0 var(--layout-side-padding) var(--layout-side-padding);
    box-sizing: border-box;
    flex-grow: 1;
    max-width: 100%;
`

const StyledSearchContainer = styled.div`
    color: rgba(0, 0, 0, 0.87);
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
    }
`

const StyledSearchRow = styled.div`
    display: flex;
    max-width: 100%;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
    }
`

const StyledSearchContainerInner = styled.div`
    padding-right: 48px;
    max-width: calc(100% - 198px);

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-right: 0;
        max-width: 100%;
    }
`

const StyledResultNumber = styled.div`
    height: 54px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledResultNumberButton = styled(Button)`
    height: 100%;
`

const Catalog: FC<IProps> = ({
    searchContainerRef,
    toolMeta,
    filterMeta,
    tools,
    filters,
    filtersValues,
    searchValue,
    totalResults,
    areToolsLoading,
    page,
    selectedMachine,
    selectedTab,
    search,
    onCustomPage,
    selectTab,
}) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()
    const resultsRef = useRef<HTMLDivElement | null>(null)
    const filtersRef = useRef<HTMLDivElement | null>(null)

    return (
        <StyledContainer>
            <StyledCenterColumn>
                <StyledSearchContainer>
                    <StyledSearchRow>
                        <StyledSearchContainerInner ref={searchContainerRef}>
                            <SearchInput
                                searchValue={searchValue}
                                areToolsLoading={areToolsLoading}
                                onSubmit={() => {
                                    search({
                                        q: searchValue,
                                        filtersValues,
                                        page,
                                    })
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value
                                    reduxDispatch(changeSearchValue(value))
                                    search({
                                        q: value,
                                        filtersValues,
                                        page,
                                    })
                                }}
                            />
                        </StyledSearchContainerInner>

                        <StyledResultNumber>
                            <StyledResultNumberButton
                                variant='outlined'
                                color='primary'
                                size='large'
                                onClick={() => {
                                    const results = resultsRef.current

                                    if (!results) {
                                        return
                                    }

                                    const offset =
                                        filtersValues && filtersValues.length > 0 ? -102 : 0

                                    window.scroll({
                                        top: results?.offsetTop + offset,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <Typography variant='body1'>
                                    {totalResults} {t('results')}
                                </Typography>
                            </StyledResultNumberButton>
                        </StyledResultNumber>
                    </StyledSearchRow>
                </StyledSearchContainer>

                <TopFilters
                    ref={filtersRef}
                    filtersGroupsMeta={filterMeta.filterGroups as IFiltersGroupMeta[]}
                    filters={filters}
                    filtersValues={filtersValues}
                    selectedMachine={selectedMachine}
                />

                <ChosenFilters
                    filtersMeta={filterMeta.filterGroups as IFiltersGroupMeta[]}
                    filtersValues={filtersValues}
                    filtersRef={filtersRef}
                />

                <Results
                    ref={resultsRef}
                    selectedTab={selectedTab}
                    tools={tools}
                    areToolsLoading={areToolsLoading}
                    toolMeta={toolMeta}
                    selectTab={selectTab}
                />

                <StyledPagination
                    page={page}
                    count={Math.ceil(totalResults / PER_PAGE) || 1}
                    variant='outlined'
                    shape='rounded'
                    onChange={(_, page) => {
                        onCustomPage(page)
                    }}
                />
            </StyledCenterColumn>
        </StyledContainer>
    )
}

export default Catalog
