import React, { FC } from 'react'
import { get } from 'lodash'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITool } from '../../../../interfaces/tool.interface'
import { IToolMetaFragment, IToolAttributeMeta } from '../../../../fragments/tool-meta.fragment'

const HeaderCell: FC<{ column: IToolAttributeMeta }> = ({ column }) => {
    const { t } = useTranslation()

    return <TableCell align={column.align || 'center'}>{t(column.label)}</TableCell>
}

const RowCell: FC<{ column: IToolAttributeMeta; value: number | string }> = ({ column, value }) => {
    const { t } = useTranslation()

    return (
        <TableCell align={column.align || 'center'}>
            <Tooltip
                title={<Typography variant='caption'>{t(column.label)}</Typography>}
                placement='bottom'
            >
                <Typography variant='caption'>
                    {typeof value === 'string' ? t(value) : value}
                </Typography>
            </Tooltip>
        </TableCell>
    )
}

const TechnicalDetailsTab: FC<{ meta: IToolMetaFragment; tools: ITool[] }> = ({ meta, tools }) => (
    <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
            <TableHead>
                <TableRow>
                    {meta.detail.map((column) => (
                        <HeaderCell key={column.id} column={column} />
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {tools.map((tool: ITool) => {
                    return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={tool.id}>
                            {meta.detail.map((column) => (
                                <RowCell
                                    key={column.id}
                                    column={column}
                                    value={get(tool, column.id)?.toString() || '—'}
                                />
                            ))}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </TableContainer>
)

export default TechnicalDetailsTab
