import React, { FC } from 'react'
import { Box } from '@mui/material'

interface IProps {
    readonly index: string | number
    readonly value: string | number
}

const TabPanel: FC<IProps> = ({ children, value, index, ...other }) => {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                marginTop: '16px',
            }}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

export default TabPanel
