import { IFiltersGroupMeta } from '../../../interfaces/filters-meta.interface'
import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const fetchFiltersMeta =
    (filtersGroupsMeta: IFiltersGroupMeta[]) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'FETCH_FILTERS_META',
            payload: filtersGroupsMeta,
        })
    }
