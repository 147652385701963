import { Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
        margin-top: 0;
    }
`

export const StyledValue = styled(Typography)`
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
        font-weight: 400;
    }
`
export const StyledCaption = styled(Typography)`
    color: ${({ theme }) => theme.palette.grey[500]};
    flex-basis: 50%;
    flex-shrink: 0;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`
export const StyledDivider = styled(Typography)`
    color: ${({ theme }) => theme.palette.grey[500]};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`

export const StyledSpec = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 64px;
    min-width: 80px;
    margin-top: 16px;

    &:last-child {
        margin-right: 0;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: row-reverse;
        margin-top: 12px;
        margin-right: 0;
        min-width: initial;
        justify-content: flex-end;
    }
`
