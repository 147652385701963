import * as Sentry from '@sentry/gatsby'
import axios from 'axios'
import queryString from 'query-string'
import { API_URL } from '../constants/api'
import { CLIENT_ID_HEADER_NAME } from '../constants/headers'
import { PER_PAGE } from '../constants/main'
import { FilterMetaTypeEnum } from '../interfaces/filters-meta.interface'
import {
    FilterValueType,
    IFilters,
    IFilterValueListItem,
    IFilterValueRange,
    IFilterValueSingleItem,
} from '../interfaces/filters.interface'
import { IIdentifiable } from '../interfaces/identifiable.interface'

interface ISearchAcc {
    [key: string]: string[] | string
}

export interface ISearchResult {
    readonly results: Array<IIdentifiable>
    readonly filters: IFilters
    readonly total: number
}

interface IPreparedForSearchValues {
    readonly [key: string]: string[] | string
}

const prepareForSearch = (filtersValues: FilterValueType[]): IPreparedForSearchValues => {
    return filtersValues.reduce((acc: ISearchAcc, item) => {
        if (item.type === FilterMetaTypeEnum.SINGLE) {
            const { objectType, attribute, value } = item as IFilterValueSingleItem

            acc[`${objectType}:${attribute}`] = [value] || []
            return acc
        }

        if (item.type === FilterMetaTypeEnum.LIST) {
            const { objectType, attribute, values } = item as IFilterValueListItem

            acc[`${objectType}:${attribute}`] = values || []
            return acc
        }
        const { objectType, attribute, from, to } = item as IFilterValueRange
        acc[`${objectType}:${attribute}_from`] = from.toString()
        acc[`${objectType}:${attribute}_to`] = to.toString()
        return acc
    }, {})
}

export interface IFetchToolsParams {
    readonly q: string
    readonly filtersValues: FilterValueType[]
    readonly page: number
    readonly sort?: string
    readonly order?: string
}

type FetchToolsType = (params: IFetchToolsParams, clientId: string) => Promise<ISearchResult | null>

export const fetchTools: FetchToolsType = async (
    { q, filtersValues, page, sort, order },
    clientId
) => {
    const params = queryString.stringify({
        q,
        offset: PER_PAGE * (page - 1),
        limit: PER_PAGE,
        sort,
        order,
        ...prepareForSearch(filtersValues),
    })

    try {
        const { data } = await axios.get<ISearchResult>(`${API_URL}/tools?${params}`, {
            headers: {
                [CLIENT_ID_HEADER_NAME]: clientId,
            },
        })

        return data
    } catch (error) {
        Sentry.captureException(error)
        return null
    }
}
