import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { replaceQueryParams } from '../../helpers/replace-query-params'
import { convertSearchParamsToQS, stringifyFiltersValues } from '../../helpers/url-search-params'
import { IReduxState } from '../../state/interfaces/state.interface'

export const useSearchParamsToQS = (areToolsLoading: boolean): void => {
    const { filtersValues, searchValue, page, sorting } = useSelector(
        (state: IReduxState) => state.filters
    )

    const filtersValuesStr = stringifyFiltersValues(filtersValues)

    useEffect(() => {
        if (areToolsLoading) {
            return
        }
        const searchParamsQS = convertSearchParamsToQS({
            searchValue,
            page,
            filtersValues: filtersValues,
            sortingMethod: sorting.method,
            sortingProperty: sorting.property,
            sortingOrder: sorting.order,
        })
        if (searchParamsQS === '') {
            replaceQueryParams('')
        } else {
            replaceQueryParams(searchParamsQS)
        }
    }, [
        areToolsLoading,
        page,
        filtersValuesStr,
        searchValue,
        sorting.method,
        sorting.order,
        sorting.property,
    ])
}
