import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const removeCartTool =
    (toolId: number) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'REMOVE_CART_TOOL',
            payload: toolId,
        })
    }
