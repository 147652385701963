import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import styled from 'styled-components'

import { IFiltersGroupMeta } from '../../../interfaces/filters-meta.interface'
import { FilterValueType, IFilters } from '../../../interfaces/filters.interface'
import MachinesFiltersGroup from './MachinesFiltersGroup'
import ToolsFiltersGroup from './ToolsFiltersGroup'
import { IMachine } from '../../../interfaces/machine.interface'

interface IProps {
    readonly filtersGroupsMeta: IFiltersGroupMeta[]
    readonly filters: IFilters
    readonly filtersValues: FilterValueType[]
    readonly selectedMachine: IMachine | null
}

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 16px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        flex-wrap: wrap;
    }
`

const StyledMachinesFiltersGroup = styled(MachinesFiltersGroup)`
    margin-right: 150px;

    ${({ theme }) => theme.breakpoints.down('xl')} {
        margin-right: 120px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-right: 0;
        margin-bottom: 36px;
        flex-grow: 1;
    }
`

const StyledToolsFiltersGroup = styled(ToolsFiltersGroup)``

const TopFilters: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
    { filtersGroupsMeta, filters, filtersValues, selectedMachine },
    ref
) => {
    const [machinesGroup, toolsGroup] = filtersGroupsMeta

    return (
        <StyledContainer ref={ref}>
            <StyledMachinesFiltersGroup
                machinesGroup={machinesGroup}
                filters={filters}
                filtersValues={filtersValues}
                selectedMachine={selectedMachine}
            />
            <StyledToolsFiltersGroup
                toolsGroup={toolsGroup}
                filters={filters}
                filtersValues={filtersValues}
            />
        </StyledContainer>
    )
}

export default forwardRef<HTMLDivElement, IProps>(TopFilters)
