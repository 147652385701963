import { useAuth0 } from '@auth0/auth0-react'
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import _ from 'lodash'
import React, { FunctionComponent, RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FilterMetaTypeEnum, IFiltersGroupMeta } from '../../../interfaces/filters-meta.interface'
import { FilterValueType } from '../../../interfaces/filters.interface'
import { clearAllFilters } from '../../../state/actions/filters/clear-all-filters'
import { removeOptionFromFilter } from '../../../state/actions/filters/remove-option-from-filter'
import { removeRangeFromFilter } from '../../../state/actions/filters/remove-range-from-filter'
import TopActionLink, { TopActionLinkTypeEnum } from '../TopActionLink'
import CustomChip from './CustomChip'
import { findFilterLabelById, prepareChipsValues } from './helpers'
import {
    ShowLessChip,
    ShowMoreChip,
    StyledContainer,
    StyledFiltersClear,
    StyledIconUpContainer,
    StyledInner,
    StyledListContainer,
    StyledMobileUp,
    StyledRight,
} from './styles'

interface IProps {
    readonly filtersMeta: IFiltersGroupMeta[]
    readonly filtersValues: FilterValueType[]
    readonly filtersRef: RefObject<HTMLDivElement>
}

const NOT_EXPANDED_COUNT_MAPPER = { xs: 1, default: 2 }

const ChosenFilters: FunctionComponent<IProps> = ({ filtersMeta, filtersValues, filtersRef }) => {
    const reduxDispatch = useDispatch()
    const [areExpanded, setAreExpanded] = useState(false)
    const [isSticked, setIsSticked] = useState(false)
    const { t } = useTranslation()
    const { isAuthenticated } = useAuth0()

    const hasFilters = filtersValues.length !== 0

    const theme = useTheme()
    const isXsWide = useMediaQuery(theme.breakpoints.down('sm'))

    const notExpandedCount = isXsWide
        ? NOT_EXPANDED_COUNT_MAPPER.xs
        : NOT_EXPANDED_COUNT_MAPPER.default

    const intersectionCbFn = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        setIsSticked(!entry.isIntersecting)
    }

    const intersectionOptions = {
        root: null,
        threshold: 0,
    }

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCbFn, intersectionOptions)
        if (filtersRef.current) {
            observer.observe(filtersRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [filtersRef.current])

    const flattenFiltersMeta = filtersMeta
        .flatMap(({ subgroups }) => subgroups)
        .flatMap(({ filters }) => filters)

    const onDeleteListItemClick =
        (objectType: string, attribute: string, filterValue: string) => () => {
            reduxDispatch(
                removeOptionFromFilter({
                    objectType,
                    attribute,
                    option: filterValue,
                })
            )
        }

    const onDeleteRangeClick = (objectType: string, attribute: string) => () => {
        reduxDispatch(
            removeRangeFromFilter({
                objectType,
                attribute,
            })
        )
    }

    const preparedValues = prepareChipsValues(filtersValues)
    const values = areExpanded ? preparedValues : _.take(preparedValues, notExpandedCount)

    // TODO: remove this function asap
    function translateLabel(objectType: string, attribute: string, label: string): string {
        if (
            objectType === 'tool' &&
            attribute === 'design' &&
            label === 'abgewinkelt zurueckgesetzt'
        ) {
            return t(`tools:design.90_zurueckgesetzt`)
        } else {
            const buckets = [
                'weight',
                'shaft_length',
                'nominal_height',
                'torque',
                'speed',
                'max_power',
            ]

            const dictionaryName =
                ['manufacturer_name', 'shaft_type', 'shaft_size'].includes(attribute) ||
                (objectType === 'machine' && attribute === 'model')
                    ? 'machines'
                    : 'tools'
            const value =
                _.snakeCase(
                    buckets.includes(attribute)
                        ? label.replace('unter ', '').replace('über ', '').replace(' bis ', ' – ')
                        : label
                ) || 'null'
            return t(`${dictionaryName}:${attribute}.${value}`)
        }
    }

    return (
        <StyledContainer isSticked={isSticked}>
            <StyledInner>
                {hasFilters && (
                    <StyledListContainer>
                        {values.map((filterValue) => {
                            const { attribute, objectType, value, type } = filterValue
                            const label = findFilterLabelById(
                                flattenFiltersMeta,
                                `${objectType}:${attribute}`
                            )

                            if (!label) {
                                return null
                            }

                            if (
                                type === FilterMetaTypeEnum.LIST ||
                                type === FilterMetaTypeEnum.SINGLE
                            ) {
                                return (
                                    <CustomChip
                                        key={`${objectType}:${attribute}:${value}`}
                                        label={areExpanded ? label : null}
                                        value={translateLabel(objectType, attribute, value)}
                                        onClick={onDeleteListItemClick(
                                            objectType,
                                            attribute,
                                            value
                                        )}
                                    />
                                )
                            }

                            return (
                                <CustomChip
                                    key={`${objectType}:${attribute}_range`}
                                    label={areExpanded ? label : null}
                                    value={translateLabel(objectType, attribute, value)}
                                    onClick={onDeleteRangeClick(objectType, attribute)}
                                />
                            )
                        })}
                        {!areExpanded && preparedValues.length - notExpandedCount > 0 && (
                            <ShowMoreChip onClick={() => setAreExpanded(true)}>
                                <Typography variant='body2'>
                                    +&nbsp;
                                    {preparedValues.length - notExpandedCount}
                                </Typography>
                            </ShowMoreChip>
                        )}
                        {areExpanded && (
                            <ShowLessChip onClick={() => setAreExpanded(false)}>
                                <Typography variant='body2'>{t('filters:show-less')}</Typography>
                            </ShowLessChip>
                        )}
                        <StyledFiltersClear>
                            <Button
                                variant='text'
                                value='Clear all'
                                onClick={() => reduxDispatch(clearAllFilters())}
                            >
                                {t('filters:clear-all')}
                            </Button>
                        </StyledFiltersClear>
                    </StyledListContainer>
                )}
                {isSticked && (
                    <StyledRight>
                        <StyledIconUpContainer
                            onClick={() => {
                                window.scroll({ top: 0, behavior: 'smooth' })
                            }}
                        >
                            <ArrowUpwardIcon />
                        </StyledIconUpContainer>
                        <TopActionLink isHeaderLink actionType={TopActionLinkTypeEnum.COMPARE} />
                        {isAuthenticated && (
                            <TopActionLink isHeaderLink actionType={TopActionLinkTypeEnum.CART} />
                        )}
                    </StyledRight>
                )}
                {isSticked && (
                    <StyledMobileUp
                        onClick={() => {
                            window.scroll({ top: 0, behavior: 'smooth' })
                        }}
                    >
                        <ArrowUpwardIcon />
                    </StyledMobileUp>
                )}
            </StyledInner>
        </StyledContainer>
    )
}

export default ChosenFilters
