import { Divider } from '@mui/material'
import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const loading = keyframes`
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(min(100vw, 690px));
  }
`

const StyledImageContainer = styled.div`
    padding: 16px 0px 16px 16px;
    flex-grow: 0;
`

const StyledContentContainer = styled.div`
    margin: 16px 0;
`

const StyledRow = styled.div`
    display: flex;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 50px;
        background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
        animation: ${loading} 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
`

const StyledImageSkeletonSVG = styled.div`
    display: block;
    width: 130px;
    height: 170px;
    border-radius: 8px;
    background-color: #bbb;
`

const StyledHorizontal = styled.div`
    flex-grow: 1;
    padding: 0 32px;

    ${({ theme }) => theme.breakpoints.down('lg')} {
        padding: 0 24px;
    }
`

const StyledContentSkeleton = styled.div`
    height: 40px;
    border-radius: 8px;
    background-color: #bbb;
`

const StyledContentSkeleton1 = styled(StyledContentSkeleton)`
    max-width: 520px;
    width: 100%;
`

const StyledContentSkeleton2 = styled(StyledContentSkeleton)`
    max-width: 210px;
    width: 40%;
    margin-top: 24px;
`

const Skeleton: FC = () => {
    return (
        <>
            {[1, 2, 3, 4, 5].map((i) => (
                <React.Fragment key={i}>
                    <Divider light />
                    <StyledRow>
                        <StyledImageContainer>
                            <StyledImageSkeletonSVG />
                        </StyledImageContainer>
                        <StyledHorizontal>
                            <StyledContentContainer>
                                <StyledContentSkeleton1 />
                                <StyledContentSkeleton2 />
                            </StyledContentContainer>
                        </StyledHorizontal>
                    </StyledRow>
                </React.Fragment>
            ))}
        </>
    )
}

export default Skeleton
