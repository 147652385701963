import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export interface IRemoveRangeFromFilterActionParams {
    readonly objectType: string
    readonly attribute: string
}

export const removeRangeFromFilter =
    (params: IRemoveRangeFromFilterActionParams) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'REMOVE_RANGE_FROM_FILTER',
            payload: params,
        })
    }
