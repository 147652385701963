import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export interface IRemoveOptionFromFilterActionParams {
    readonly objectType: string
    readonly attribute: string
    readonly option: string
}

export const removeOptionFromFilter =
    (params: IRemoveOptionFromFilterActionParams) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'REMOVE_OPTION_FROM_FILTER',
            payload: params,
        })
    }
