import { ICartTool } from '../../../interfaces/cart-tool.interface'
import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const addCartTool =
    (cartTool: ICartTool) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'ADD_CART_TOOL',
            payload: cartTool,
        })
    }
