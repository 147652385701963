import { ChangeEvent } from 'react'
import { IBuildFragment } from '../fragments/build.fragment'
import { IFilterMetaFragment } from '../fragments/filter-meta.fragment'
import { IToolMetaFragment } from '../fragments/tool-meta.fragment'

export enum ProductTabEnum {
    GENERAL = 'General',
    TECHNICAL_DETAILS = 'Technical Details',
}

export interface IData {
    readonly build: IBuildFragment
    readonly filterMeta: IFilterMetaFragment
    readonly toolMeta: IToolMetaFragment
}

export type SelectTabType = (event: ChangeEvent<unknown>, payload: ProductTabEnum) => void
