import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const changeSearchValue =
    (searchValue: string) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'CHANGE_SEARCH_VALUE',
            payload: searchValue,
        })
    }
