import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export const removeComparedToolId =
    (toolId: number) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'REMOVE_COMPARED_TOOL_ID',
            payload: toolId,
        })
    }
