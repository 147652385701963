import { SortingMethodEnum, SortingOrderEnum } from '../../../interfaces/sorting.interface'
import { AppDispatchType } from '../../interfaces/app-dispatch.type'

export interface IChangeSortingMethodActionParams {
    readonly newMethod: SortingMethodEnum
    readonly newProperty: string | null
    readonly newOrder: SortingOrderEnum
}

export const changeSortingMethod =
    ({ newMethod, newProperty, newOrder }: IChangeSortingMethodActionParams) =>
    (dispatch: AppDispatchType): void => {
        dispatch({
            type: 'CHANGE_SORTING_METHOD',
            payload: {
                newMethod,
                newProperty,
                newOrder,
            },
        })
    }
