import { Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { EMPTY_FILTER_VALUE } from '../../../../constants/main'
import { IFiltersGroupMeta } from '../../../../interfaces/filters-meta.interface'
import { FilterValueType, IFilters } from '../../../../interfaces/filters.interface'
import { IMachine } from '../../../../interfaces/machine.interface'
import SelectFilter from '../SelectFilter'

interface IProps {
    readonly className?: string
    readonly machinesGroup: IFiltersGroupMeta
    readonly filters: IFilters
    readonly filtersValues: FilterValueType[]
    readonly selectedMachine: IMachine | null
}

const StyledContainer = styled.div``

const StyledFiltersContainer = styled.div`
    display: flex;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-wrap: wrap;
        display: block;
    }
`

const StyledMachineImageContainer = styled.div`
    width: 432px;
    margin-top: 48px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 100%;
        margin-top: 32px;
    }
`

const StyledMachineImage = styled.img`
    max-width: 100%;
`

const StyledSelectFilter = styled(SelectFilter)`
    &:not(:last-child) {
        margin-right: 50px;
    }

    ${({ theme }) => theme.breakpoints.down('xl')} {
        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }
`

const StyledTypographyContainer = styled.div`
    margin-bottom: 12px;
`

const StyledTypography = styled(Typography)`
    font-size: 24px;
    margin-bottom: 12px;
`

const MachinesFiltersGroup: FC<IProps> = ({
    className,
    machinesGroup,
    filters,
    filtersValues,
    selectedMachine,
}) => {
    const { t } = useTranslation()
    const [highlightedDepsIds, setHighlightedDepsIds] = useState<string[]>([])

    const label = machinesGroup.label
    const generalSubgroup = machinesGroup.subgroups[0]
    const isImagePresent =
        selectedMachine &&
        selectedMachine.attributes.src &&
        selectedMachine.attributes.src !== EMPTY_FILTER_VALUE

    return (
        <StyledContainer className={className}>
            <StyledTypographyContainer>
                <StyledTypography style={{ margin: 0 }}>{t(`filters:${label}`)}</StyledTypography>
            </StyledTypographyContainer>
            <StyledFiltersContainer>
                {generalSubgroup.filters.map((filterMeta) => {
                    return (
                        <StyledSelectFilter
                            key={filterMeta.id}
                            filterMeta={filterMeta}
                            filters={filters}
                            filtersValues={filtersValues}
                            isHighlighted={highlightedDepsIds.includes(filterMeta.id)}
                            setHighlightedDepsIds={setHighlightedDepsIds}
                        />
                    )
                })}
            </StyledFiltersContainer>
            {isImagePresent && (
                <StyledMachineImageContainer>
                    <StyledMachineImage src={selectedMachine?.attributes.src}></StyledMachineImage>
                </StyledMachineImageContainer>
            )}
        </StyledContainer>
    )
}

export default MachinesFiltersGroup
