import React, { FC, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FilterValueType, IFilters } from '../../../../interfaces/filters.interface'

import { FilterMetaTypeEnum, IFilterMeta } from '../../../../interfaces/filters-meta.interface'
import { isDisabled, generateDisabledMessage } from '../../../../helpers/filters'
import MultipleSelect from './MultipleSelect'
import SingleSelect from './SingleSelect'
import FormControlWrapper from './FormControlWrapper'
import { IReduxState } from '../../../../state/interfaces/state.interface'
import { sortOptionsByNumber } from './utils/sort-options-by-number'

interface IProps {
    readonly className?: string
    readonly filterMeta: IFilterMeta
    readonly filters: IFilters
    readonly filtersValues: FilterValueType[]
    readonly isHighlighted: boolean
    readonly setHighlightedDepsIds: (deps: string[]) => void
}

const autocompleteCss = css`
    width: 200px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 100%;
    }
`

const StyledSingleSelect = styled(SingleSelect)`
    ${autocompleteCss}
`

const StyledMultipleSelect = styled(MultipleSelect)`
    ${autocompleteCss}
`

const SelectFilter: FC<IProps> = ({
    className,
    filterMeta,
    filters,
    filtersValues,
    isHighlighted,
    setHighlightedDepsIds,
}) => {
    const { t } = useTranslation()

    const { id, label, type, dependencies } = filterMeta
    const filtersMeta = useSelector((state: IReduxState) => state.filters.meta)

    const isEmptyFilterData = !filters[id]

    const isCurrentDisabled = !isEmptyFilterData && isDisabled(filters, filtersValues, dependencies)
    const disabledMessage =
        isCurrentDisabled && !!dependencies
            ? generateDisabledMessage({
                  label,
                  dependencies,
                  filtersMeta,
                  translate: t,
              })
            : null

    const existedOptions = isEmptyFilterData
        ? []
        : Object.keys(filters[id]).sort(sortOptionsByNumber)

    const highlightDeps = useCallback(() => {
        dependencies && setHighlightedDepsIds(dependencies.map(({ id }) => id))
    }, [])

    const resetDeps = useCallback(() => {
        setHighlightedDepsIds([])
    }, [])

    const onMouseEnter = isCurrentDisabled ? highlightDeps : undefined
    const onMouseLeave = isCurrentDisabled ? resetDeps : undefined

    if (type === FilterMetaTypeEnum.LIST) {
        return (
            <FormControlWrapper
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <StyledMultipleSelect
                    filterMeta={filterMeta}
                    existedOptions={existedOptions}
                    filtersValues={filtersValues}
                    isHighlighted={isHighlighted}
                    isDisabled={isCurrentDisabled}
                    disabledMessage={disabledMessage}
                />
            </FormControlWrapper>
        )
    }

    // type === FilterValueTypeEnum.SINGLE
    return (
        <FormControlWrapper
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <StyledSingleSelect
                filterMeta={filterMeta}
                existedOptions={existedOptions}
                filtersValues={filtersValues}
                isHighlighted={isHighlighted}
                isDisabled={isCurrentDisabled}
                disabledMessage={disabledMessage}
            />
        </FormControlWrapper>
    )
}

export default SelectFilter
