import React, { FC } from 'react'

import { StyledValue, StyledCaption, StyledContainer, StyledDivider, StyledSpec } from './styles'
import { useTranslation } from 'react-i18next'
import { addOptionToFilter } from '../../../../../state/actions/filters/add-option-to-filter'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

interface IProps {
    readonly coolantSupply: string | null
    readonly gearRatio: string | null
    readonly toolHolderStandard: string | null
    readonly toolHolderType: string | null
    readonly toolHolderSize: string | null
    readonly toolHolderSubtype: string | null
    readonly direction: string
    readonly nominalHeight: number
}

interface ISpecAttribute {
    id: string
    value: string | number | null
}

interface ISpec {
    label: string
    attributes: ISpecAttribute[]
}

const SingleValue: FC<ISpecAttribute & { endWithDivider?: boolean }> = ({
    id,
    value,
    endWithDivider,
}) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()

    return (
        <>
            <StyledValue
                variant='body1'
                onClick={() => {
                    const [objectType, attributeName] = id.split(':')
                    reduxDispatch(
                        addOptionToFilter({
                            objectType,
                            attribute: attributeName,
                            // TODO: fix the dictionary name
                            option: value?.toString() || `${objectType}s:${attributeName}.null`,
                        })
                    )
                }}
            >
                {typeof value === 'string' ? t(value) : value}
            </StyledValue>
            {endWithDivider && <StyledDivider>&nbsp;·&nbsp;</StyledDivider>}
        </>
    )
}

const GeneralTabContent: FC<IProps> = ({
    coolantSupply,
    gearRatio,
    toolHolderStandard,
    toolHolderType,
    toolHolderSize,
    toolHolderSubtype,
    direction,
    nominalHeight,
}) => {
    const { t } = useTranslation()

    const specs: ISpec[] = [
        {
            label: t('coolant_supply'),
            attributes: [
                {
                    id: 'tool:coolant_supply',
                    value: coolantSupply,
                },
            ],
        },
        {
            label: t('gear_ratio'),
            attributes: [
                {
                    id: 'tool:gear_ratio',
                    value: gearRatio,
                },
            ],
        },
        {
            label: t('direction'),
            attributes: [
                {
                    id: 'tool:direction',
                    value: direction,
                },
            ],
        },
        {
            label: t('nominal_height'),
            attributes: [
                {
                    id: 'tool:nominal_height',
                    value: nominalHeight,
                },
            ],
        },
        {
            label: t('tool_holder'),
            attributes: [
                {
                    id: 'tool:tool_holder_standard',
                    value: toolHolderStandard,
                },
                {
                    id: 'tool:tool_holder_type',
                    value: toolHolderType,
                },
                {
                    id: 'tool:tool_holder_size',
                    value: toolHolderSize,
                },
                {
                    id: 'tool:tool_holder_subtype',
                    value: toolHolderSubtype,
                },
            ],
        },
    ]

    function prepareValue(attributes: ISpecAttribute[]) {
        if (attributes.length === 0) {
            return '—'
        }

        const nonEmptyAttributes = attributes.filter((e) =>
            typeof e.value === 'string' ? t(e.value) : e.value
        )

        if (nonEmptyAttributes.length === 0) {
            const { id } = attributes[0]
            const [objectType, attributeName] = id.split(':')
            // TODO: fix the dictionary name
            return <SingleValue id={id} value={`${objectType}s:${attributeName}.null`} />
        } else {
            return nonEmptyAttributes.map((e, i) => (
                <SingleValue
                    key={e.value}
                    id={e.id}
                    value={e.value}
                    endWithDivider={i < nonEmptyAttributes.length - 1}
                />
            ))
        }
    }

    return (
        <StyledContainer>
            {specs.map(({ label, attributes }) => (
                <StyledSpec key={label}>
                    <Grid container>{prepareValue(attributes)}</Grid>
                    <StyledCaption>{label}</StyledCaption>
                </StyledSpec>
            ))}
        </StyledContainer>
    )
}

export default GeneralTabContent
