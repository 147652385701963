import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { CLIENT_ID_COOKIE_NAME } from '../../constants/cookies'
import { fetchTools, IFetchToolsParams } from '../../helpers/fetch-tools'
import { stringifyFiltersValues } from '../../helpers/url-search-params'
import { IFilters } from '../../interfaces/filters.interface'
import { SortingMethodEnum, SortingOrderEnum } from '../../interfaces/sorting.interface'
import { ITool } from '../../interfaces/tool.interface'
import { IReduxState } from '../../state/interfaces/state.interface'

interface IFetchedToolsData {
    readonly tools: ITool[]
    readonly totalResults: number
    readonly filters: IFilters
    readonly areToolsLoading: boolean
}

interface IUseSearchResult {
    readonly fetchedToolsData: IFetchedToolsData
    readonly debouncedSearch: _.DebouncedFunc<(params: IFetchToolsParams) => Promise<void>>
}

export const useSearch = (tools: ITool[]): IUseSearchResult => {
    const defaultValues = {
        tools: [],
        totalResults: 0,
        filters: {},
        areToolsLoading: true,
    }

    const [fetchedToolsData, setFetchedToolsData] = useState<IFetchedToolsData>(defaultValues)
    const { filtersValues, searchValue, page, sorting } = useSelector(
        (state: IReduxState) => state.filters
    )

    const filtersValuesStr = stringifyFiltersValues(filtersValues)

    const [clientIdCookies] = useCookies([CLIENT_ID_COOKIE_NAME])
    const clientId = clientIdCookies[CLIENT_ID_COOKIE_NAME]

    const debouncedSearch = useCallback(
        _.debounce(async ({ q, filtersValues, page, sort, order }) => {
            const fetchedTools = await fetchTools({ q, filtersValues, page, sort, order }, clientId)

            if (!fetchedTools) {
                return
            }

            const toolsMap = _.mapValues(_.groupBy(tools, 'id'), (e) => e[0])
            setFetchedToolsData({
                filters: fetchedTools.filters,
                tools: _.map(fetchedTools.results, (r) => toolsMap[r.id]),
                totalResults: fetchedTools.total,
                areToolsLoading: false,
            })
        }, 200),
        []
    )

    useEffect(() => {
        debouncedSearch({
            q: searchValue,
            filtersValues: filtersValues,
            page,
            sort:
                sorting.method === SortingMethodEnum.RELEVANCE
                    ? undefined
                    : sorting.property || undefined,
            order:
                sorting.method === SortingMethodEnum.RELEVANCE &&
                sorting.order === SortingOrderEnum.DESC
                    ? undefined
                    : sorting.order,
        })
        return () => {
            debouncedSearch.cancel()
        }
    }, [page, filtersValuesStr, searchValue, sorting.method, sorting.order, sorting.property])

    return { debouncedSearch, fetchedToolsData }
}
