import React, { FC } from 'react'
import styled from 'styled-components'

import { Grid, Typography } from '@mui/material'

import Specs from '../Specs'
import Actions from '../Actions'
import { ITool } from '../../../../../interfaces/tool.interface'
import { useTranslation } from 'react-i18next'
import StockLabel from '../StockLabel'

interface IProps {
    readonly tool: ITool
}

const StyledGrid = styled(Grid)`
    color: rgba(0, 0, 0, 0.87);
    background: white;
    border: 0;
    display: flex;
    min-width: 0;
    content-visibility: auto;
`

const StyledImageContainer = styled.div`
    width: 50%;
    padding: 16px;
`

const StyledContentContainer = styled.div`
    padding: 16px 0;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
`

const StyledTabContentWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const StyledTabGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
`

const StyledImage = styled.img`
    width: 100px;
    height: 150px;
    object-fit: contain;
`

const StyledId = styled.div`
    display: flex;
`

const StyledIdTypographyTitle = styled(Typography)`
    font-size: 18px;
    margin-bottom: 6px;
    margin-right: 18px;
`

const StyledIdTypographyLabel = styled(Typography)`
    color: rgba(0, 0, 0, 0.38);
`

const StyledIdTypography = styled(Typography)``

const DesktopToolItem: FC<IProps> = ({ tool }) => {
    const { t } = useTranslation()

    return (
        <StyledGrid container>
            <Grid container item xs={2} alignItems='center'>
                <StyledImageContainer>
                    <StyledImage
                        src={`${process.env.GATSBY_CDN_HOST}/${tool.attributes.code}_sm`}
                        alt='tool image'
                    />
                </StyledImageContainer>
            </Grid>

            <StyledTabGrid item xs={10}>
                <StyledContentContainer>
                    <StyledTabContentWrapper>
                        <div>
                            <Grid container item>
                                <StyledIdTypographyTitle variant='body1'>
                                    {t(tool.attributes.name_key)}
                                </StyledIdTypographyTitle>
                                <StockLabel tool={tool} />
                            </Grid>
                            <StyledId>
                                <StyledIdTypographyLabel>id:&nbsp;</StyledIdTypographyLabel>
                                <StyledIdTypography>{tool.attributes.code}</StyledIdTypography>
                            </StyledId>
                        </div>
                        <Specs
                            coolantSupply={tool.attributes.coolant_supply_key}
                            gearRatio={tool.attributes.gear_ratio_key}
                            toolHolderStandard={tool.attributes.tool_holder_standard_key}
                            toolHolderType={tool.attributes.tool_holder_type_key}
                            toolHolderSize={tool.attributes.tool_holder_size_key}
                            toolHolderSubtype={tool.attributes.tool_holder_subtype_key}
                            direction={tool.attributes.model_key}
                            nominalHeight={tool.attributes.nominal_height}
                        />
                    </StyledTabContentWrapper>
                    <Actions id={tool.id} downloads={tool.documents} />
                </StyledContentContainer>
            </StyledTabGrid>
        </StyledGrid>
    )
}

export default DesktopToolItem
