import React, { FC } from 'react'
import styled from 'styled-components'
import { FormControl } from '@mui/material'

interface IProps {
    readonly className?: string
    readonly onMouseEnter?: () => void
    readonly onMouseLeave?: () => void
}

const StyledFormControl = styled(FormControl)`
    width: 100%;
`

const FormControlWrapper: FC<IProps> = ({ children, className, onMouseEnter, onMouseLeave }) => (
    <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <StyledFormControl>{children}</StyledFormControl>
    </div>
)

export default FormControlWrapper
