import { Grid, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ITool } from '../../../../../interfaces/tool.interface'
import Actions from '../Actions'
import Specs from '../Specs'
import StockLabel from '../StockLabel'

interface IProps {
    readonly tool: ITool
}

const StyledContainer = styled.div`
    content-visibility: auto;
`

const StyledDescriptionGrid = styled(Grid)`
    color: var(--text-primary-color);
    background: white;
    border: 0;
    min-width: 0;
    display: flex;
`

const StyledImageContainer = styled.div`
    padding: 16px;
`

const StyledDescription = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const StyledImage = styled.img`
    width: auto;
    height: 90px;
`

const StyledId = styled.div`
    display: flex;
`

const StyledStockLabel = styled.div`
    margin-top: 16px;
`

const StyledIdTypographyTitle = styled(Typography)`
    font-size: 18px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledIdTypographyLabel = styled(Typography)`
    color: var(--text-disabled-color);
`

const MobileToolItem: FC<IProps> = ({ tool }) => {
    const { t } = useTranslation()

    return (
        <StyledContainer>
            <StyledDescriptionGrid container>
                <Grid container item xs={4} alignItems='center'>
                    <StyledImageContainer>
                        <StyledImage
                            src={`${process.env.GATSBY_CDN_HOST}/${tool.attributes.code}_sm`}
                            alt='tool image'
                        />
                    </StyledImageContainer>
                </Grid>

                <StyledDescription item xs={8}>
                    <StyledIdTypographyTitle variant='body1'>
                        {t(tool.attributes.name_key)}
                    </StyledIdTypographyTitle>
                    <StyledId>
                        <StyledIdTypographyLabel>id:&nbsp;</StyledIdTypographyLabel>
                        <Typography>{tool.attributes.code}</Typography>
                    </StyledId>
                    <StyledStockLabel>
                        <StockLabel tool={tool} />
                    </StyledStockLabel>
                </StyledDescription>
            </StyledDescriptionGrid>
            <Specs
                coolantSupply={tool.attributes.coolant_supply_key}
                gearRatio={tool.attributes.gear_ratio_key}
                toolHolderStandard={tool.attributes.tool_holder_standard_key}
                toolHolderType={tool.attributes.tool_holder_type_key}
                toolHolderSize={tool.attributes.tool_holder_size_key}
                toolHolderSubtype={tool.attributes.tool_holder_subtype_key}
                direction={tool.attributes.model_key}
                nominalHeight={tool.attributes.nominal_height}
            />
            <Actions id={tool.id} downloads={tool.documents} />
        </StyledContainer>
    )
}

export default MobileToolItem
