import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

import LeaderboardSvg from '../assets/leaderboard.svg'
import ShoppingCartSvg from '../assets/shopping-cart.svg'
import { IReduxState, IToolsState } from '../../../state/interfaces/state.interface'
import { URL_ARRAY_FORMAT } from '../../../constants/comparison'

interface IProps {
    readonly className?: string
    readonly actionType: TopActionLinkTypeEnum
    readonly isHeaderLink?: boolean
}

const StyledActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
        margin-right: 24px;
    }
`

const StyledTypography = styled(Typography)`
    color: var(--text-primary-color);
`

const StyledContainer = styled(StyledActionContainer)`
    width: 64px;
    &:hover {
        svg {
            fill: var(--text-secondary-color);
        }
        ${StyledTypography} {
            color: var(--text-secondary-color);
        }
    }
`

const StyledCountLabel = styled.div`
    position: absolute;
    top: 0;
    right: 9px;
    padding: 0px 8px;
    background: var(--primary-main-color);
    border-radius: 8px;
    color: #fff;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        top: -2px;
        right: -9px;
    }
`
const StyledCountLabelTypography = styled(Typography)`
    font-size: 10px;
`

const StyledLabel = styled.div`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

export enum TopActionLinkTypeEnum {
    CART = 'cart',
    COMPARE = 'comparison',
}

type MapperType = {
    readonly [key in TopActionLinkTypeEnum]: {
        readonly icon: React.ReactElement
        readonly textKey: string
        readonly storeKey: keyof IToolsState
    }
}

const mapper: MapperType = {
    [TopActionLinkTypeEnum.CART]: {
        icon: <ShoppingCartSvg />,
        textKey: 'common:cart',
        storeKey: 'cartTools',
    },
    [TopActionLinkTypeEnum.COMPARE]: {
        icon: <LeaderboardSvg />,
        textKey: 'common:compare',
        storeKey: 'comparedToolIds',
    },
}

const TopActionLink: FC<IProps> = ({ className, actionType, isHeaderLink }) => {
    const { t } = useTranslation()
    const tools = useSelector((state: IReduxState) => state.tools)

    const toolIds = tools[mapper[actionType].storeKey]
    const icon = mapper[actionType].icon
    const label = t(mapper[actionType].textKey)

    const count = toolIds.length

    const toolIdsString = queryString.stringify(
        { ids: toolIds },
        {
            arrayFormat: URL_ARRAY_FORMAT,
        }
    )

    const linkUrl =
        actionType === TopActionLinkTypeEnum.CART ? '/cart' : `/comparison?${toolIdsString}`

    return (
        <Link to={linkUrl} className={className}>
            <StyledContainer>
                <div>{icon}</div>
                <StyledLabel>
                    <StyledTypography variant={isHeaderLink ? 'body2' : 'body1'}>
                        {label}
                    </StyledTypography>
                </StyledLabel>
                <StyledCountLabel>
                    <StyledCountLabelTypography>{count}</StyledCountLabelTypography>
                </StyledCountLabel>
            </StyledContainer>
        </Link>
    )
}

export default TopActionLink
