// extract ^number from string and sort by it
export const sortOptionsByNumber = (a: string, b: string): number => {
    const aNum = /^(\d+).*/g.exec(a)
    const bNum = /^(\d+).*/g.exec(b)

    if (aNum !== null && bNum !== null) {
        return parseInt(aNum[1]) - parseInt(bNum[1])
    } else {
        return a.localeCompare(b)
    }
}
