import { useAuth0 } from '@auth0/auth0-react'
import { Button, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { GetApp, ShoppingCart } from '@mui/icons-material'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { IDownloadItem } from '../../../../../interfaces/download-item.interface'
import { addCartTool as addCartToolAction } from '../../../../../state/actions/tools/add-cart-tool'
import { addComparedToolId as addComparedToolIdAction } from '../../../../../state/actions/tools/add-compared-tool-id'
import { removeCartTool as removeCartToolIdAction } from '../../../../../state/actions/tools/remove-cart-tool'
import { removeComparedToolId as removeComparedToolIdAction } from '../../../../../state/actions/tools/remove-compared-tool-id'
import { AppDispatchType } from '../../../../../state/interfaces/app-dispatch.type'
import { IReduxState } from '../../../../../state/interfaces/state.interface'
import {
    removeCartToolFromLS,
    removeComparedToolFromLS,
    saveCartToolToLS,
    saveComparedToolToLS,
} from '../../../../../utils/local-storage'
import LeaderboardFilledSvg from '../../../assets/leaderboard-filled.svg'
import LeaderboardSvg from '../../../assets/leaderboard.svg'

interface IProps {
    readonly id: number
    readonly downloads: IDownloadItem[]
}

const StyledDesktopActions = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const commonIconCss = css`
    cursor: pointer;
`

const StyledLeaderboardSvg = styled(LeaderboardSvg)`
    ${commonIconCss}
    fill: var(--primary-main-color);
`

const StyledLeaderboardFilledSvg = styled(LeaderboardFilledSvg)`
    ${commonIconCss}
    fill: white;
`

const StyledMobileLeaderboardSvg = styled(LeaderboardSvg)`
    ${commonIconCss}
`

const StyledMobileLeaderboardFilledSvg = styled(LeaderboardFilledSvg)`
    ${commonIconCss}
`

const StyledMobileAction = styled.div`
    &:not(:last-child) {
        margin-right: 24px;
    }
`

const StyledButton = styled(Button)`
    white-space: nowrap;
    min-width: 180px;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const StyledDownloadLink = styled.a`
    &,
    &:visited {
        color: var(--text-primary-color);
    }

    &:hover {
        color: var(--text-secondary-color);
    }
`

const StyledMobileActions = styled.div`
    display: none;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: flex;
    }
`

const Actions: FC<IProps> = ({ id, downloads }) => {
    const { t } = useTranslation()
    const { comparedToolIds, cartTools } = useSelector((state: IReduxState) => state.tools)
    const [isDownloadsOpened, setIsDownloadsOpened] = useState(false)
    const menuButton = useRef(null)
    const { isAuthenticated } = useAuth0()

    const reduxDispatch: AppDispatchType = useDispatch()

    const addComparedToolId = () => {
        saveComparedToolToLS(id)
        reduxDispatch(addComparedToolIdAction(id))
    }

    const removeComparedToolId = () => {
        removeComparedToolFromLS(id)
        reduxDispatch(removeComparedToolIdAction(id))
    }

    const addCartToolId = () => {
        saveCartToolToLS({ id, quantity: 1 })
        reduxDispatch(addCartToolAction({ id, quantity: 1 }))
    }

    const removeCartToolId = () => {
        removeCartToolFromLS(id)
        reduxDispatch(removeCartToolIdAction(id))
    }

    const isCompared = comparedToolIds.includes(id)
    const isInCart = cartTools.map((item) => item.id).includes(id)

    const theme = useTheme()
    const isXsWide = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            {!isXsWide && (
                <StyledDesktopActions>
                    {isCompared ? (
                        <StyledButton
                            variant='contained'
                            onClick={() => removeComparedToolId()}
                            startIcon={<StyledLeaderboardFilledSvg />}
                        >
                            {t('common:unmark')}
                        </StyledButton>
                    ) : (
                        <StyledButton
                            variant='outlined'
                            onClick={() => addComparedToolId()}
                            startIcon={<StyledLeaderboardSvg />}
                        >
                            {t('common:add')}
                        </StyledButton>
                    )}
                    <StyledButton
                        variant='outlined'
                        aria-haspopup='true'
                        ref={menuButton}
                        onClick={() => setIsDownloadsOpened(true)}
                        startIcon={<GetApp />}
                    >
                        {t('common:downloads')}
                    </StyledButton>
                    {isAuthenticated && (
                        <>
                            {isInCart ? (
                                <StyledButton
                                    variant='contained'
                                    onClick={() => removeCartToolId()}
                                    startIcon={<ShoppingCart />}
                                >
                                    {t('common:remove-from-cart')}
                                </StyledButton>
                            ) : (
                                <StyledButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => addCartToolId()}
                                    startIcon={<ShoppingCart />}
                                >
                                    {t('common:add-to-cart')}
                                </StyledButton>
                            )}
                        </>
                    )}
                </StyledDesktopActions>
            )}
            {isXsWide && (
                <StyledMobileActions>
                    <StyledMobileAction>
                        {isCompared ? (
                            <StyledMobileLeaderboardFilledSvg
                                onClick={() => removeComparedToolId()}
                            />
                        ) : (
                            <StyledMobileLeaderboardSvg onClick={() => addComparedToolId()} />
                        )}
                    </StyledMobileAction>
                    <StyledMobileAction>
                        <GetApp
                            onClick={() => setIsDownloadsOpened(true)}
                            ref={menuButton}
                            aria-controls='downloads-menu'
                        />
                    </StyledMobileAction>
                    {isAuthenticated && (
                        <StyledMobileAction>
                            <ShoppingCart onClick={() => undefined} />
                        </StyledMobileAction>
                    )}
                </StyledMobileActions>
            )}
            <Menu
                variant='menu'
                id='downloads-menu'
                open={isDownloadsOpened}
                anchorEl={menuButton.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClick={() => setIsDownloadsOpened(false)}
            >
                {downloads.map(({ assetType, language, src }) => {
                    const name = `${assetType} ${language}`
                    const languageLabel = language === 'neutral' ? '' : `(${language})`
                    return (
                        <MenuItem key={name} value={name}>
                            <StyledDownloadLink href={src} target='_blank' rel='noreferrer'>
                                {`${t(`general:glossary.${assetType}`)} ${languageLabel}`}
                            </StyledDownloadLink>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

export default Actions
