import { graphql, PageProps, useStaticQuery } from 'gatsby'
import React, { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Catalog from '../components/Catalog'
import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'
import { useQSToSearchParams } from '../hooks/catalog/use-qs-to-search-params'
import { useSearch } from '../hooks/catalog/use-search'
import { useSearchParamsToQS } from '../hooks/catalog/use-search-params-to-qs'
import { IFilterValueSingleItem } from '../interfaces/filters.interface'
import { IMachine } from '../interfaces/machine.interface'
import { IData, ProductTabEnum, SelectTabType } from '../interfaces/main-page.interface'
import { changePage } from '../state/actions/filters/change-page'
import { AppDispatchType } from '../state/interfaces/app-dispatch.type'
import { IReduxState } from '../state/interfaces/state.interface'

const IndexPage: FC<PageProps> = ({ location }) => {
    const reduxDispatch: AppDispatchType = useDispatch()
    const { filtersValues, searchValue, page } = useSelector((state: IReduxState) => state.filters)

    const data: IData = useStaticQuery(graphql`
        query {
            build: externalJson {
                ...ExternalFragment
            }
            filterMeta: filterMetaJson {
                ...FilterMetaFragment
            }
            toolMeta: toolMetaJson {
                ...ToolMetaFragment
            }
        }
    `)

    const [selectedTab, setSelectedTab] = useState(ProductTabEnum.GENERAL)

    const searchContainerRef = useRef<HTMLDivElement | null>(null)

    const onCustomPage = (page: number) => {
        reduxDispatch(changePage(page))
    }

    const selectTab: SelectTabType = (_, payload) => {
        setSelectedTab(payload)
    }

    const { debouncedSearch, fetchedToolsData } = useSearch(data.build.tools)
    const { tools, filters, totalResults, areToolsLoading } = fetchedToolsData
    useQSToSearchParams(location.search, data.filterMeta.filterGroups)
    useSearchParamsToQS(areToolsLoading)

    const machineOptions = Object.keys(filters['machine:model'] || {})
    const autofilledMachineName: string | null =
        machineOptions.length === 1 ? machineOptions[0] : null

    const selectedMachineModelName =
        (
            filtersValues.find(
                ({ objectType, attribute }) => objectType === 'machine' && attribute === 'model'
            ) as IFilterValueSingleItem
        )?.value || autofilledMachineName

    const selectedMachine: IMachine | null =
        data.build.machines.find((m) => m.attributes.model_key === selectedMachineModelName) || null

    return (
        <Layout>
            <SEO title='Products' />
            <Catalog
                searchContainerRef={searchContainerRef}
                toolMeta={data.toolMeta}
                filterMeta={data.filterMeta}
                tools={tools}
                filters={filters}
                filtersValues={filtersValues}
                searchValue={searchValue}
                totalResults={totalResults}
                areToolsLoading={areToolsLoading}
                page={page}
                selectedMachine={selectedMachine}
                selectedTab={selectedTab}
                search={debouncedSearch}
                onCustomPage={onCustomPage}
                selectTab={selectTab}
            />
        </Layout>
    )
}

export default IndexPage
